#manage-scenes-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#manage-scenes-segments-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

#breadcrumb-bar-container {
  width: 100%;
}

#scene-options-container {
  width: 100%;
  margin-bottom: -4px;
}

#scenes-and-general-options-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
}

#scene-options-and-scenes-container {
  width: 100%;
  height: calc(100% - 75px);
  margin-right: 10px;
}

#general-options-container {
  width: 300px;
}

h3, h4 {
  color: rgba(0, 0, 0, 0.6);
}

.segment-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
  margin-right: 10px;
}
