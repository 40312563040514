.list-item-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: 0.1s;
}

.list-item-content:hover {
  background-color: rgb(223, 225, 226, 0.4);
  border-radius: 5px;
  margin-right: 15px;
  transition: 0.1s;
}

#selected-list-item {
  background-color: rgb(223, 225, 226, 1);
  border-radius: 5px;
  margin-right: 15px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0);
}

#scene-preview-container {
  border-radius: 5px;
  flex-shrink: 0;
  width: 180px;
  height: 80px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 20px;
}

#scene-preview-hover {
  z-index: 2;
  opacity: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  object-fit: cover;
  border-radius: 5px;
  width: 180px;
  height: 80px;
  transition: 0.2s;
}

#scene-preview-hover:hover {
  opacity: 1;
  transition: 0.2s;
}

#scene-preview {
  z-index: 1;
  position: absolute;
  object-fit: cover;
  border-radius: 5px;
  width: 180px;
  height: 80px;
}

.scene-row-text {
  margin-left: 15px;
  margin-right: 50px;
  color: rgba(0, 0, 0, 0.6);
}

#map-location-set-label {
  margin-right: 10px;
}

#enlarge-icon {
  margin-top: 25px;
  font-size: 30pt;
  color: white !important;
}
