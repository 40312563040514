.Line {
  stroke: rgb(255,0,0);
  stroke-width: 2;
}

.SVG {
  position: fixed;
  top: 0;
  left: 0;
}
