#new-collection-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#form-container {
  width: 800px;
}
