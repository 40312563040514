#header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #90A4AE;
  flex-shrink: 0;
  position: relative;
}

.left-button-container {
  margin-right: 20px;
}

.right-button-container {
  margin-right: 18px;
}

#title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #607D8B;
  margin-right: 22px;
}

#zag-title {
  font-size: 18pt;
}

.logo-text {
  color: #f2f3f4;
}

.name-text {
  font-size: 10pt;
  color: rgba(0, 0, 0, 0.6);
}

#name-container {
  height: 100%;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
}

#left-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#right-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
}

#top-layer {
  margin-left: 175px;
  width: calc(100% - 175px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  z-index: 2;
}

#particles-layer {
  position: absolute;
  z-index: 1;
  opacity: 0.5;
}

#main-header-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
