.GeneralOptions {
  padding: 10px 10px 0px 0px;
  width: 300px;
}

.GeneralOptionsSegment {
  height: 100%;
  margin-bottom: 100px;
}

.GeneralOptionsButtonSeparator {
  height: 20px;
}

.SegmentContent {
  padding: 2px 8px 8px 8px;
}
