.Title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
}

.Title > h1 {
  color: #546E7A;
}
