.Selector {
  pointer-events: none;
  position: absolute;
  z-index: 2;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border-width: 5px;
  border-style: transparent;
  border-color: #000000;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 0px 8px 4px rgba(20, 20, 20, 0.3); */
}
