#collections-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#scroll-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1290px;
  height: 100%;
  margin-bottom: -8px;
  overflow: hidden;
}

#collection-cards-container {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#no-collections-message-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
