#root-container {
  height: 100%;
  width: 100%;
}

#app {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background-color: #FAFAFA;
}

#unsupported-resolution {
  width: 100%;
}

#main-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ui.form textarea {
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
}

.ui.modal > .header {
  color: rgba(0,0,0,.6) !important;
}

.ui.dimmer {
  transition: background-color 0.3s ease;
  background-color: transparent;
}

.modal-fade-in .ui.dimmer {
  transition: background-color 0.3s ease;
  background-color: rgba(250, 250, 250, 0.8);
}

/* Disables the grey bar that appears due to a bug with the React Custom Scrollbars package. */
body ::-webkit-scrollbar-track {
  background: rgb(0, 0, 0, 0);
}

@media screen and (min-width: 1385px) {
  html {
    /* -moz-transform: scale(0.85, 0.85); */
    zoom: 0.80;
    zoom: 80%;
  }
}

@media screen and (max-width: 1385px) {
  html {
    /* -moz-transform: scale(0.80, 0.80); */
    zoom: 0.80;
    zoom: 80%;
  }
}

@media screen and (max-width: 1080px) {
  html {
    /* -moz-transform: scale(0.65, 0.65); */
    zoom: 0.80;
    zoom: 80%;
  }
}

@media screen and (min-width: 850px) {
  #unsupported-resolution {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  #app {
    visibility: hidden;
  }

  #unsupported-resolution {
    display: block;
    line-height: 1.7em;
    zoom: 4;
    zoom: 400%;
  }
}
