#info-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#info-message-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #607D8B;
}

#alert-icon {
  font-size: 30pt;
  padding-top: 25px;
  color: #607D8B !important;
}
