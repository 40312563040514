#scenes-segment {
  height: 100%;
}

#scroll-area-container {
  width: 99%;
  height: calc(100% - 30px);
  margin-left: 10px;
  margin-top: 10px;
}

#scroll-area {
  width: 80%;
}

#no-scenes-message-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
