#scene-options-segment-content {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.scene-options-buttons-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
