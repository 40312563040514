
.collection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 1200px;
  height: 180px;
  border-radius: 100px 10px 10px 100px;
  background-color: white;
  position: static;
  overflow: hidden;
  margin-bottom: 40px;
  box-shadow: 2px 2px 8px 0.5px rgba(0, 0, 0, 0.18);
}

.cover-image {
  object-fit: cover;
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  height: 180px;
}

.main-collection-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.info-container {
  width: 100%;
  height: 70%;
}

.collection-text-container {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 22px;
  padding-right: 22px;
}

.collection-text {
  color: rgba(0,0,0,.6);
}

.collection-title {
  font-size: 20pt;
  margin-bottom: 10px;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 30%;
  padding-right: 7px;
}

.tags-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: auto;
}

.button-container {
  margin-left: 7px;
}

#deletion-warning-modal {
  position: fixed;
  top: 37vh;
  left: 63vw;
  width: 470px;
}

#deletion-warning-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.tag-container {
  margin-right: 10px;
}
