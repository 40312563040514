.SegmentContent {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
}

.BreadcrumbLabel {
  margin-right: 10px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.BreadcrumbContainer {
  display: flex;
  flex-direction: row;
}
