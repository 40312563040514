#main-content-container {
  flex-grow: 1;
  width: 100%;
  height: calc(100% - 60px);
}

#page-title {
  margin-top: 12px;
  margin-bottom: 14px;
  color: #546E7A;
}
