.LinkListItem {
  display: flex;
  flex-direction: row;
}

.LinkButton {
  width: 210px;
  height: 60px;
}

.Button {
  width: 40px;
  height: 60px;
}
