.LinkList {
  height: 100%;
  padding: 10px;
  flex-grow: 1;
  max-width: 450px;
}

.LinkListSegment {
  height: 100%;
  margin-bottom: 100px;
}

.LinkListSeparator {
  height: 20px;
}

.SegmentContent {
  height: 100%;
  padding: 2px 8px 8px 8px;
}

.InfoMessageContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
