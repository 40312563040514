#general-options-segment {
  height: 100%;
}

.general-options-button-container {
  padding: 10px;
}

.centred-button-text {
  margin-left: 22px;
  margin-right: -12px;
}
