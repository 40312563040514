.form-item {
  margin: 20px;
}

.text {
  color: rgba(0,0,0,.6) !important;
}

#action-buttons-container {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.action-button-container {
  margin-left: 10px;
  margin-right: 10px;
}

#description {
  resize: none;
}

#image-upload-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#image-picker-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#scene-image-preview-container {
  border-radius: 5px;
  flex-shrink: 0;
  margin-left: auto;
}

#scene-image-preview {
  object-fit: cover;
  border-radius: 5px;
  width: 350px;
  height: 175px;
}
