.FormItem {
  margin: 20px;
}

.Text {
  color: rgba(0,0,0,.6) !important;
}

.ActionButtonsContainer {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ActionButtonContainer {
  margin-left: 10px;
  margin-right: 10px;
}
