.LinkSelection {
  height: 100%;
  display: inline-block;
  padding: 10px 10px 10px 0px;
}

.Segment {
  height: 100%;
}

.RootImageContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.ImageSeparator {
  height: 20px;
}

.SegmentContent {
  height: 100%;
  padding: 2px 8px 8px 8px;
}

.InfoMessageContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
