.ManageLinks {
  height: calc(100% - 122px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.LeftSide {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
